import translationsData from "./translations.json";

const language = ["en_US", "zh_CN", "ja_JP", "es_ES", "en-US",
   "zh-CN", "ja-JP", "es-ES", "fr-FR", "fr_FR"
];
var win = window,
  loc = win.location,
  pathname = loc.pathname,
  path = pathname.split("/"),
  selectedLanguage = language[0];

  language.map((elem) => {
  path.map((a) => {
    if (a === elem) selectedLanguage = elem;
    return a;
  });
  return elem;
});


switch (selectedLanguage) {
  case "zh-CN":
  case "zh_CN":
    selectedLanguage = 'chinese';
    break;
  case "ja-JP":
  case "ja_JP":
    selectedLanguage = 'japanese';
    break;
  case "es-ES":
  case "es_ES":
    selectedLanguage = 'spanish';
    break;
  case "fr-FR":
  case "fr_FR":
    selectedLanguage = 'french';
    break;
  default:
    selectedLanguage = 'english';
}

/*
 NA values in cell will result in empty space;
 Empty values in cell will result in default english translation;
*/

export default function translations(id) {
  let value = translationsData.filter(
    function(data) {
        return data.id === id
    }
  )[0][selectedLanguage]

  if(value){
    if(value === 'NA') return '';

    return value;

  } else {
    return translationsData.filter(
      function(data) {
          return data.id === id
      }
    )[0]['english'];
  }
}

