import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { fromJS } from "immutable";
import * as queryString from "query-string";
import {
  CommonGameController,
  CommonMultiGameController,
} from "component/common";
import { GameItem, GameItemBanner } from "component/game";
import { Games4by2 } from "component/list";
import { RelatedCategoryListItem } from "component/listItem";

import "containers/Games/styles.scss";

class Games extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.gamesRef = createRef();
    this.ghostRef = createRef();
    this.launchGame = this._launchGame.bind(this);
    this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
    this.onCloseGameHandler = this._onCloseGameHandler.bind(this);
    this.setRelatedLinks = this._setRelatedLinks.bind(this);
    this.onFullscreenHandler = this._onFullscreenHandler.bind(this);
    this.fullscreenChangeHandler = this._fullscreenChangeHandler.bind(this);
    this.setMultiGame = this._setMultiGame.bind(this);
    this.onRefreshClickHandler = this._onRefreshClickHandler.bind(this);
    this.onDraggingItem = this._onDraggingItem.bind(this);
    this.onDrop = this._onDrop.bind(this);
    this.onDragOver = this._onDragOver.bind(this);
    this.onDragEnd = this._onDragEnd.bind(this);
    this.state = {
      multiGameCount: 1,
      relatedLinks: null,
      relatedParams: null,
      relatedRoute: null,
      draggedItem: null,
      itemOrder: [0, 1, 2, 3],
    };
  }

  _setRelatedLinks(route, params) {
    this.setState({
      relatedRoute: route,
      relatedParams: params,
      relatedLinks: null,
    });
    this.props.fetchRelatedLinks(route, params).then((resp) => {
      this.setState({
        relatedLinks: fromJS(resp),
      });
      return resp;
    });
  }

  _launchGame(i, gameParam) {
    let games = this.props.games.setIn([i], gameParam);

    let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));

    let params = {
      games: gamesEnc,
    };

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;

    this.props.navigateTo(newLoc);
  }

  _onCloseClickHandler() {
    this.props.fetchBalance();
    this.props.onGameClose({
      location: this.props.location,
    });
    this.setState({
      multiGameCount: 1,
      relatedLinks: null,
      relatedParams: null,
      relatedRoute: null,
      draggedItem: null,
      itemOrder: [0, 1, 2, 3],
    });
    let search = queryString.parse(this.props.location.search);
    delete search.games;
    this.props.navigateTo(`${this.props.location.pathname}`);
  }

  _onCloseGameHandler(i) {
    let games = this.props.games.setIn([i], null);

    let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));

    let params = {
      games: gamesEnc,
    };

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;

    this.props.navigateTo(newLoc);
  }

  _onRefreshClickHandler(i) {
    let game = this.props.games.getIn([i]);
    game = game.setIn(
      ["refresh"],
      game.getIn(["refresh"]) ? game.getIn(["refresh"]) + 1 : 1
    );

    let games = this.props.games.setIn([i], game);
    let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));

    let params = {
      games: gamesEnc,
    };

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;

    this.props.navigateTo(newLoc);
  }

  _onFullscreenHandler(bol) {
    let elem = this.containerRef.current;
    this.props.setGameFullscreen(bol);
    if (bol) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else {
      if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  _fullscreenChangeHandler(event) {
    if (document.webkitIsFullScreen === false) {
      this.onFullscreenHandler(false);
    } else if (document.mozFullScreen === false) {
      this.onFullscreenHandler(false);
    } else if (document.msFullscreenElement === false) {
      this.onFullscreenHandler(false);
    }
  }

  _setMultiGame(i) {
    let games = this.props.games;
    for (var j = i; j < 4; j++) {
      games = games.setIn([i], null);
    }

    let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));

    let params = {
      games: gamesEnc,
    };

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;

    this.props.navigateTo(newLoc);
    this.setState({
      multiGameCount: i,
    });
  }

  componentDidMount() {
    document.addEventListener("fullscreenchange", this.fullscreenChangeHandler);

    if (!this.props.games) return;

    let size = this.props.games.findLastIndex((game) => {
      return game !== null;
    });

    this.setState({
      multiGameCount: size + 1,
    });
  }

  componentWillUnmount() {
    document.removeEventListener(
      "fullscreenchange",
      this.fullscreenChangeHandler
    );
  }

  _onDraggingItem(event) {
    this.setState({
      draggedItem: event.currentTarget,
    });

    event.currentTarget.style.zIndex = "999999";
  }

  _onDragEnd(event) {
    event.preventDefault();
    event.currentTarget.style.zIndex = "auto";
  }

  _onDragOver(event) {
    event.preventDefault();
  }

  swapOrder(array, index1, index2) {
    let temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;

    return array;
  }

  _onDrop(event) {
    let draggedOrder = Number(
      window.getComputedStyle(this.state.draggedItem).getPropertyValue("order")
    );
    let draggedIndex = this.state.itemOrder.indexOf(draggedOrder);
    let droppedOrder = Number(
      window.getComputedStyle(event.currentTarget).getPropertyValue("order")
    );
    let droppedIndex = this.state.itemOrder.indexOf(droppedOrder);

    let newArr = this.swapOrder(
      this.state.itemOrder,
      draggedIndex,
      droppedIndex
    );
    this.setState({
      itemOrder: newArr,
    });
  }

  render() {
    if (!this.props.games) return null;

    return (
      <div ref={this.containerRef} className="games-container modal">
        <div className="modal-dialog nopadding">
          <div className="modal-content">
            <CommonMultiGameController
              history={this.props.history}
              onCloseClickHandler={this.onCloseClickHandler}
              onFullscreenHandler={this.onFullscreenHandler}
              gameFullscreen={this.props.gameFullscreen}
              setMultiGame={this.setMultiGame}
              multiGameCount={this.state.multiGameCount}
              onRefreshClickHandler={() => {
                this.onRefreshClickHandler(0);
              }}
            />
            <div
              className={`games-row row nopadding justify-content-center`}
              ref={this.gamesRef}
            >
              {Array.from(Array(this.state.multiGameCount)).map((item, i) => {
                let game = this.props.games.get(i);
                //insert game check if game details
                if (
                  game &&
                  (game.get("playType") === "launch.real" ||
                    game.get("playType") === "launch.demo")
                ) {
                  return (
                    <div
                      className={`col ${
                        this.state.multiGameCount > 1 ? "col-6" : ""
                      } game-col ${
                        this.state.multiGameCount > 2 ? "multiple-row" : ""
                      } order-${this.state.itemOrder[i]}`}
                      key={i}
                      draggable={true}
                      onDragStart={this.onDraggingItem}
                      onDragOver={this.onDragOver}
                      onDrop={this.onDrop}
                      onDragEnd={this.onDragEnd}
                    >
                      <div>
                        {this.state.multiGameCount > 1 && (
                          <CommonGameController
                            onCloseClickHandler={() => {
                              this.onCloseGameHandler(i);
                            }}
                            onRefreshClickHandler={() => {
                              this.onRefreshClickHandler(i);
                            }}
                          />
                        )}
                        <GameItem
                          game={game}
                          fetchGame={(gameId) => {
                            return this.props.fetchGame(gameId).then((resp) => {
                              if (i === 0 && !this.state.relatedLinks) {
                                let params = {
                                  nav: game.get("nav"),
                                };

                                let relatedLinks = resp.payload
                                  .get("links")
                                  .find((link) => {
                                    return link.get("rel") === "related.links";
                                  });
                                this.setRelatedLinks(relatedLinks, params);
                              }
                              return resp;
                            });
                          }}
                          fetchGameUrl={(route) => {
                            return this.props
                              .fetchGameUrl(route)
                              .then((resp) => {
                                return resp;
                              });
                          }}
                        />
                      </div>
                    </div>
                  );
                } else if (game) {
                  return (
                    <div
                      className={`col ${
                        this.state.multiGameCount > 1 ? "col-6" : ""
                      } game-col ${
                        this.state.multiGameCount > 2 ? "multiple-row" : ""
                      } order-${this.state.itemOrder[i]}`}
                      key={i}
                      draggable={true}
                      onDragStart={this.onDraggingItem}
                      onDragOver={this.onDragOver}
                      onDrop={this.onDrop}
                      onDragEnd={this.onDragEnd}
                    >
                      <div>
                        {this.state.multiGameCount > 1 && (
                          <CommonGameController
                            onCloseClickHandler={() => {
                              this.onCloseGameHandler(i);
                            }}
                            onRefreshClickHandler={() => {
                              this.onRefreshClickHandler(i);
                            }}
                          />
                        )}
                        <GameItemBanner
                          game={game}
                          fetchGame={(gameId) => {
                            return this.props.fetchGame(gameId).then((resp) => {
                              if (i === 0 && !this.state.relatedLinks) {
                                let params = {
                                  nav: game.get("nav"),
                                };

                                let relatedLinks = resp.payload
                                  .get("links")
                                  .find((link) => {
                                    return link.get("rel") === "related.links";
                                  });
                                this.setRelatedLinks(relatedLinks, params);
                              }
                              return resp;
                            });
                          }}
                          fetchGameUrl={(route) => {
                            return this.props
                              .fetchGameUrl(route)
                              .then((resp) => {
                                return resp;
                              });
                          }}
                          onGameItemClickHandler={(item) => {
                            this.launchGame(i, item);
                          }}
                          imageFormat={this.props.imageFormat}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`col ${
                        this.state.multiGameCount > 1 ? "col-6" : ""
                      } game-col ${
                        this.state.multiGameCount > 2 ? "multiple-row" : ""
                      } order-${this.state.itemOrder[i]}`}
                      key={i}
                      draggable={true}
                      onDragStart={this.onDraggingItem}
                      onDragOver={this.onDragOver}
                      onDrop={this.onDrop}
                      onDragEnd={this.onDragEnd}
                    >
                      <div>
                        <div className="game-item related-container">
                          <CommonGameController
                            onRefreshClickHandler={() => {
                              this.setRelatedLinks(
                                this.state.relatedRoute,
                                this.state.relatedParams
                              );
                            }}
                          />
                          {this.state.relatedLinks &&
                            this.state.relatedLinks.map((route, j) => {
                              let filters = this.props.filters;
                              console.log("filters:", filters.toJSON());
                              return (
                                <Games4by2
                                  key={j}
                                  route={route}
                                  itemComponent={RelatedCategoryListItem}
                                  fetchGames={() => {
                                    let params = new URLSearchParams();
                                    this.props.games.forEach((game) => {
                                      if (game) {
                                        params.append(
                                          "excludedId",
                                          game.get("gameId")
                                        );
                                      }
                                    });
                                    console.log("route:", route.toJSON());
                                    return this.props.fetchGames(route, params);
                                  }}
                                  isMobile={this.props.isMobile}
                                  onGameItemClickHandler={(item) => {
                                    this.launchGame(i, item);
                                  }}
                                  imageFormat={this.props.imageFormat}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let isMultiGame = state.links.get("isMultiGame");
  let isMobile = state.window.get("isMobile");
  let { games } = queryString.parse(window.location.search);
  let gamesDecrypt = null;

  if (!isMultiGame && !isMobile && games === undefined) {
    return {
      games: null,
    };
  }

  try {
    gamesDecrypt = fromJS(JSON.parse(decodeURIComponent(atob(games))));
  } catch (err) {}

  return {
    isMultiGame,
    isMobile,
    games: gamesDecrypt,
    gameFullscreen: state.config.get("gameFullscreen"),
    imageFormat: state.window.get("imageFormat"),
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    logout: () => dispatchProps.logout(),
    fetchGame: (params) => dispatchProps.fetchGame(params),
    fetchGameUrl: (route) => dispatchProps.fetchGameUrl(route),
    fetchBalance: () => dispatchProps.fetchBalance(),
    onGameClose: (params) => dispatchProps.onGameClose(params),
    navigateTo: (path) => ownProps.history.push(`${path}`),
    fetchRelatedLinks: (route, params) =>
      dispatchProps.fetchRelatedLinks(route, params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    setGameFullscreen: (bol) => dispatchProps.setGameFullscreen(bol),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Games);
