import React, {
	Component,
	Fragment
} from 'react';
import translations from 'translations';
import {
	currencyFormatter
} from 'utils/currencyHelper';
import { ReactComponent as InfoIcon} from 'assets/icons/information-button.svg';

import {
	convertMS
} from 'utils/date';
import {
	isEqual
} from 'lodash';

import 'component/common/HeaderDesktop/styles.scss';

class CommonHeaderDesktop extends Component {
	constructor(props) {
		super(props)
		this.requestAnimationFrameHandler = this._requestAnimationFrameHandler.bind(this);
		this.animationFrameId = null;
		this.state = {
			startsIn: null,
			endsIn: null
		}
	}

	componentDidMount() {
		this.requestAnimationFrameHandler(this.props.tournamentPreview);
	}

	componentWillUnmount() {
		window.cancelAnimationFrame( this.animationFrameId );
	}

	componentWillReceiveProps(nextProps) {

		if(nextProps.tournamentPreview && !isEqual(nextProps.tournamentPreview, this.props.tournamentPreview)) {
			window.cancelAnimationFrame( this.animationFrameId );
			this.requestAnimationFrameHandler(nextProps.tournamentPreview);
		}
	}

	_requestAnimationFrameHandler(tournamentPreview) {
		if(tournamentPreview) {
			let newDate = new Date().getTime()
			let startsIn = tournamentPreview.get('startsIn') === 0 ? null : convertMS(tournamentPreview.get('startsIn') - newDate)
			let endsIn = tournamentPreview.get('endsIn') === 0 ? null : convertMS(tournamentPreview.get('endsIn') - newDate);
			let startsInDHS = startsIn ? { d: startsIn.d, h: startsIn.h, m: startsIn.m } : null;
			let endsInDHS = endsIn ? {d: endsIn.d, h: endsIn.h, m: endsIn.m} : null;
			let started = (tournamentPreview.get('startsIn') - newDate <= 0)
			let ended = (tournamentPreview.get('endsIn') - newDate <= 0)

			if(!isEqual(startsInDHS, this.state.startsIn) || !isEqual(endsInDHS, this.state.endsIn)) {
				this.setState({
					startsIn: started ? null : startsInDHS,
					endsIn: ended ? null : endsInDHS
				})
			}
		}
		this.animationFrameId = window.requestAnimationFrame(() =>{
			this.requestAnimationFrameHandler(tournamentPreview)
		})
	}

	render() {
			const {
				tournamentComponent,
				tournamentPreview,
				title,
				link,
				showCategoryLabel
			} = this.props;

			const headerName = this.props.link.get('name');
			const hasFreeRounds = this.props.link.get('rel') === 'free.rounds-claim.rewards';

			let LeftPanel = this.props.leftPanel;
			let RightPanel = this.props.rightPanel;
			let headerClass = '';
			if(this.props.leftPanel && this.props.rightPanel) {
				headerClass = 'with-left-right';
			} else if (this.props.leftPanel) {
				headerClass = 'with-left';
			} else if (this.props.rightPanel) {
				headerClass = 'with-right'
			}

			return <div className="common-header-desktop nopadding row">
				{
					this.props.leftPanel && <div className="col-auto left-panel nopadding">
						<LeftPanel />
					</div>
				}
				<div className={`details-actions ${ showCategoryLabel ? 'fade-in' : ''}`} ref={this.actionLabel}>
					{
						<span className={`action-name ${this.props.isJackpotView || hasFreeRounds || tournamentPreview || tournamentComponent ? 'rewards' : ''}`}>
							<div>{ title ? title.get('title') : link.get('name') }</div>
						</span>
					}
				</div>
				<div className="col d-flex align-items-center nopadding">
				{
					!hasFreeRounds && !tournamentPreview && <span className={`label ${headerClass} ${ link.get('rel').replace(/[^\w\s]/gi, '')}`}>
						{ link.get('name') }
					</span>
				}
				{
					hasFreeRounds && <span className={`label ${headerClass} ${hasFreeRounds ? link.get('rel').replace(/[^\w\s]/gi, '') : ''}`}>
						{ translations(`claimYourRewards`) }
					</span>
				}
				{
						tournamentPreview && <Fragment>
								<span className={`tournament-name ${headerClass}`}>
										<div className="name">
											{ headerName }
											{
												this.state.startsIn && <b className="starts-in"> {translations('comingSoon')}</b>
											}
											{
												this.state.endsIn && !this.state.startsIn && <b className="ends-in"> {translations('ongoing')}</b>
											}
											{
												!this.state.endsIn && !this.state.startsIn && <b className="ended"> {translations('ended')}</b>
											}
										</div>
										<div className="row">
											<div className="col-auto">
												<div>{`${translations('prizePool')} ${currencyFormatter( tournamentPreview.get('prizePool'), {currency: tournamentPreview.get('currency')})}`}</div>
												{
														this.state.startsIn && <div>{`${translations('startsIn')} ${this.state.startsIn.d}${translations('dateD')} : ${this.state.startsIn.h}${translations('dateH')}: ${this.state.startsIn.m}${translations('dateM')}`}</div>
												}
												{
														this.state.endsIn && !this.state.startsIn && <div>{`${translations('endsIn')}  ${this.state.endsIn.d}${translations('dateD')}: ${this.state.endsIn.h}${translations('dateH')}: ${this.state.endsIn.m}${translations('dateM')}`}</div>
												}
											</div>
											{
												this.props.detailsAvailable && <div className="col-auto d-flex align-items-center nopadding">
													<InfoIcon className="info-icon" onClick={(event) => {
														event.stopPropagation();
														this.props.onInfoClick();
														}}/>
													</div>
											}
											<div className="col"></div>
										</div>
								</span>

						</Fragment>
				}
				</div>
				{
					this.props.rightPanel && <div className="col-auto right-panel nopadding">
						<RightPanel />
					</div>
				}
		</div>

	}
}

export default CommonHeaderDesktop;