import React, {
  Component,
	createRef
} from 'react';
import 'component/details/Info/styles.scss';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-2.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { RibbonIcon, TrophyIcon, EditorsChoiceIcon } from 'component/icons'
import { ReactComponent as CrownIcon} from 'assets/icons/crown.svg';
import isEmpty from 'lodash/isEmpty';
import translations from 'translations';

class Info extends Component {
  constructor( props ) {
		super( props );
		this.topPosition = createRef();
		this.onClickName = this._onClickName.bind(this);
		this.state = {
			hasDescription: props.details && !isEmpty(props.details.get('description')),
			activeDetails: false,
		};
  }

  componentDidMount(){
	if (!this.props.isMobile) this.setState({ activeDetails: true })
  }

  _onClickName() {
	this.setState({ activeDetails: !this.state.activeDetails })
  }

  render() {
		const { isMobile } = this.props;
		if (!this.props.details) {
			return null;
		}

		return <div className={`component-info showBorder ${isMobile ? 'mobile' : 'desktop'}`} ref={this.topPosition} >
			<div
				className={
					`name text-center d-flex justify-content-between align-items-center`
				}
				onClick={() => {
					if(isMobile) {
						this.onClickName()
					}
				}}
      		>
				{ this.props.details.get('name') &&
					<div className="game-name">
						<span className="game-feature-badge">
						{
							this.props.details.get('hasFreeRounds') && <span className="ribbonIcon">
							<RibbonIcon
							color="rgba(255, 204, 0, 1)"
							selectedColor=""
							/>
						</span>
						}
						{
						this.props.details.get('partOfOnGoingTournament') && <span className="trophyIcon">
								<TrophyIcon
										color="rgba(255, 204, 0, 1)"
										selectedColor=""
										/>
						</span>
						}
						{
							this.props.details.get('isJackpot') && <span className="jackpotIcon">
							<CrownIcon
								color="rgba(255, 204, 0, 1)"
							/>
							</span>
							}
							{
							this.props.details.get('isFeatured') && <span className="editorsChoiceIcon">
								<EditorsChoiceIcon
								color="rgba(255, 204, 0, 1)"
								/>
							</span>
							}
						</span>
						{this.props.details.get('name')}
					</div>
				}
				{
					this.props.details.get('provider') &&
						<div className="row name-provider">
							<div className="col">
								{this.props.details.get('provider')}
							</div>
						</div>
				}
				{
					isMobile && <div> { this.state.activeDetails ? <ArrowUp />  :  <ArrowDown />  } </div>
				}
			</div>
			{
			 this.state.activeDetails && <div className="description">
				{this.props.details.get('description')}
			</div>
			}

			{
			 this.state.activeDetails && <div className="info showBorder">
				{
					this.props.details.get('gameType') &&
						<div className="row">
							<div className="col-4 label">
								{translations('type')}:
							</div>
							<div className="col">
								{this.props.details.get('gameType')}
							</div>
						</div>
				}
				{this.props.details.get('themes') &&
					this.props.details.get('themes').size > 0 && (
						<div className="row">
							<div className="col-4 label">
								{translations('themes')}:
							</div>
							<div className="col">
								{this.props.details
									.get('themes')
									.toJSON()
									.join(', ')}
							</div>
						</div>
					)}
				{this.props.details.get('specialFeatures') &&
					this.props.details.get('specialFeatures').size > 0 && (
						<div className="row">
							<div className="col-4 label">
								{translations('features')}:
							</div>
							<div className="col">
								{this.props.details
									.get('specialFeatures')
									.toJSON()
									.join(', ')}
							</div>
						</div>
					)}
				{this.props.details.get('volatility') &&
					this.props.details.get('volatility') !== '' && (
						<div className="row">
							<div className="col-4 label">
								{translations('volatility')}:
							</div>
							<div className="col">
								{this.props.details.get('volatility')}
							</div>
						</div>
					)}
				{this.props.details.get('releaseDate') && (
					<div className="row">
						<div className="col-4 label">
							{translations('releaseDate')}:
						</div>
						<div className="col">
							{this.props.details.get('releaseDate')}
						</div>
					</div>
				)}
			</div>
 			 }
    </div>
  }
}

export default Info;