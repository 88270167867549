import React, { Component, Fragment } from "react";
import { fromJS } from "immutable";
import translations from "translations";
import "component/filters/CategoryFilters/styles.scss";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";

class CategoryFilters extends Component {
  renderSortTitle(sortBy) {
    let sortTitle;
    switch (sortBy) {
      case "editor":
        sortTitle = translations('sortEditor');
        break;
      case "popularity":
        sortTitle = translations('sortPopularity');
        break;
      case "newest":
        sortTitle = translations('sortNewest');
        break;
      case "name":
        sortTitle = translations('sortName');
        break;
      case "lastPlayed":
        sortTitle = translations('sortLastPlayed');
        break;
      case "relevance":
        sortTitle = translations('sortedRelevance');
        break;
      default:
        sortTitle = "Default";
        break;
    }

    if (sortTitle.includes("並べ替え") || sortTitle.includes("排序")) {
      return (
        <span className="sorted-by">
          &#8226;
          <span
            className="sort-btn"
            onClick={(event) => {
              event.stopPropagation();
              this.props.onSortClick();
            }}
          >
            {sortTitle}
          </span>
        </span>
      );
    } else {
      return (
        <span className="sorted-by">
          &#8226; {translations("sortedBy")}
          <span
            className="sort-btn"
            onClick={(event) => {
              event.stopPropagation();
              this.props.onSortClick();
            }}
          >
            {sortTitle
              .split("by")
              .pop()
              .trim()}
          </span>
        </span>
      );
    }
  }

  getLabel(rel) {
    if (rel.indexOf("providers") > -1) return translations('providers');

    return translations('games');
  }

  render() {
    return (
      <div className="category-filters-container">
        {this.props.filterRoute && !this.props.filters && (
          <div className="category-filters">
            <span className="badge badge-secondary">
              <span className="dummy">XX XXXX</span>
            </span>
            {fromJS([1, 2, 3, 4]).map((filter, key) => {
              return (
                <span className="badge badge-secondary" key={key}>
                  <span className="dummy">XXXX XXXXXX</span>
                </span>
              );
            })}
          </div>
        )}
        {this.props.filters && (
          <div className="category-filters-toggled">
            {this.props.link.get("rel").indexOf("providers") === -1 && (
              <span
                className={`sorting-filters ${
                  this.props.sortingSize >= 1 ? "active" : ""
                }`}
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.onSortClick();
                }}
              >
                <FilterIcon />
                {!this.props.isMobile ? <span>{translations('filter')}</span> : ""}
              </span>
            )}
            {this.props.filters.map((filter, key) => {
              if (
                filter.get("values").size === 1 ||
                //  filter.get("visible") === false ||
                //) {
                filter.get("visible") === false ||
                filter.get("isExpandable")
              ) {
                return null;
              }

              return (
                <span
                  className="badge badge-secondary"
                  key={key}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.onApplyFilterClickHandler(filter);
                  }}
                >
                  {filter.get("name")}
                </span>
              );
            })}
          </div>
        )}
        {this.props.link.get("rel").indexOf("providers") === -1
          ? this.props.filters && (
              <div className="sub-category-filters-toggled">
                {this.props.filters.map((filter, key) => {
                  return (
                    <Fragment key={key}>
                      {filter.get("values") &&
                        filter.get("values").map((value, index) => {
                          if (
                            !value.get("toggled") ||
                            !value.get("enabled") ||
                            filter.get("isExpandable")
                          )
                            return null;

                          return (
                            <span
                              className="badge badge-toggled"
                              key={index}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.props.onFilterClickHandler(
                                  this.props.filterRoute,
                                  filter.get("id"),
                                  value
                                );
                              }}
                            >
                              <CloseIcon />
                              {value.get("name")}
                            </span>
                          );
                        })}
                    </Fragment>
                  );
                })}
                {this.props.filters.map((filter) => {
                  if (
                    filter.get("values").size === 1 ||
                    !filter.get("isExpandable") ||
                    filter.get("visible") === false
                  ) {
                    return null;
                  }

                  return filter.get("values").map((value, key) => {
                    if (value.get("count") === 0){
                      return null;
                    }

                    return value.get("toggled") ? (
                      <span
                        className={`badge badge-toggled ${filter.get('isMultiSelectable') ? 'category' : 'type'}`}
                        key={key}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.props.onFilterClickHandler(
                            this.props.filterRoute,
                            filter.get("id"),
                            value
                          );
                        }}
                      >
                        <CloseIcon />
                        {value.get("name")}
                      </span>
                    ) : (
                      <span
                        className={`badge badge-secondary ${filter.get('isMultiSelectable') ? 'category' : 'type'}`}
                        key={key}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.props.onFilterClickHandler(
                            this.props.filterRoute,
                            filter.get("id"),
                            value
                          );
                        }}
                      >
                        {value.get("name")}
                      </span>
                    );
                  });
                })}
              </div>
            )
          : this.props.filters && (
              <div className="sub-category-filters-toggled providers">
              <span
                className={`sorting-filters ${
                  this.props.sortingSize >= 1 ? "active" : ""
                }`}
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.onSortClick();
                }}
              >
                <FilterIcon />
                {!this.props.isMobile ? <span>{translations('filter')}</span> : ""}
              </span>
                {this.props.filters.map((filter) => {
                  if (
                    filter.get("values").size === 1 ||
                    !filter.get("isExpandable") ||
                    filter.get("visible") === false
                  ) {
                    return null;
                  }

                  return filter.get("values").map((value, key) => {
                    return (
                      <span
                        className={`badge ${
                          value.get("toggled")
                            ? "badge-toggled"
                            : "badge-secondary"
                        } `}
                        key={key}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.props.onFilterClickHandler(
                            this.props.filterRoute,
                            filter.get("id"),
                            value
                          );
                        }}
                      >
                        {value.get("name")}
                      </span>
                    );
                  });
                })}
              </div>
            )}
        {this.props.link &&
          this.props.games &&
          this.props.games.getIn(["items"]) && (
            <div className="row nopadding">
              <span className="col-auto nopadding">
                <span className="total-game-count">
                  {this.props.games.getIn(["totalCount"])}{" "}
                  {this.getLabel(this.props.link.get("rel"))}
                  {this.props.sortBy
                    ? this.renderSortTitle(this.props.sortBy)
                    : ""}
                </span>
              </span>
              {this.props.enableClearAll && (
                <span className="col">
                  {" "}
                  <u
                    className="clear-all"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.clearAllFilters(
                        this.props.filterRoute.get("rel")
                      );
                    }}
                  >
                    {translations('clearAllFilters')}
                  </u>
                </span>
              )}
            </div>
          )}
        {!this.props.games && (
          <div className="row nopadding">
            <span className={"col nopadding"}>
              <span className="total-game-count">
                <span className="dummy">XXXXXXX</span> •{" "}
                <span className="dummy">XXXXXXXXXXXX </span>
                <span className="dummy">XXXXXXX</span>
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default CategoryFilters;
