import React, { Component, createRef } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down-2.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as ScratchCardsIcon } from "assets/icons/scratch cards.svg";
import { ReactComponent as SlotIconActive } from "assets/icons/Slots-active.svg";
import { ReactComponent as SlotIconInactive } from "assets/icons/Slots-inactive.svg";
import { ReactComponent as TableGamesIconActive } from "assets/icons/TableGames-active.svg";
import { ReactComponent as TableGamesIconInactive } from "assets/icons/TableGames-inactive.svg";
import { ReactComponent as VirtualSportsIconActive } from "assets/icons/VirtualSports-active.svg";
import { ReactComponent as VirtualSportsIconInactive } from "assets/icons/VirtualSports-inactive.svg";
import { ReactComponent as CrashGamesIconActive } from "assets/icons/CrashGames-active.svg";
import { ReactComponent as CrashGamesIconInactive } from "assets/icons/CrashGames-inactive.svg";
import { ReactComponent as LiveCasinoIconActive } from "assets/icons/LiveCasino-active.svg";
import { ReactComponent as LiveCasinoIconInactive } from "assets/icons/LiveCasino-inactive.svg";
import { ReactComponent as InstantWinIconActive } from "assets/icons/InstantWin-active.svg";
import { ReactComponent as InstantWinIconInactive } from "assets/icons/InstantWin-inactive.svg";
import { ReactComponent as LotteryIconActive } from "assets/icons/Lottery-active.svg";
import { ReactComponent as LotteryIconInactive } from "assets/icons/Lottery-inactive.svg";
import { ReactComponent as ShootingIconActive } from "assets/icons/Shooting-active.svg";
import { ReactComponent as ShootingIconInactive } from "assets/icons/Shooting-inactive.svg";
import { ReactComponent as GameShowsIconActive } from "assets/icons/GameShows-active.svg";
import { ReactComponent as GameShowsIconInactive } from "assets/icons/GameShows-inactive.svg";
import { ReactComponent as EsportsIconActive } from "assets/icons/ESports-active.svg";
import { ReactComponent as EsportsIconInactive } from "assets/icons/Esports-inactive.svg";
import translations from "translations";
import "component/filters/ShowMoreFilter/styles.scss";

class ShowMoreFilter extends Component {
  constructor(props) {
    super(props);
    this.accordionRef = createRef();
    this.expandHandler = this._expandHandler.bind(this);
    this.state = {
      showMore: false,
      filterValues: props.filter.get("values"),
      expand: true,
      sortFilterValues: props.filter
        .get("values")
        .sort((a, b) => a.get("name").localeCompare(b.get("name"))),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter) {
      let sortFilterValues;
      try {
        sortFilterValues = nextProps.filter
          .get("values")
          .sort((a, b) => a.get("name").localeCompare(b.get("name")));
      } catch (err) {}
      this.setState({
        filterValues: nextProps.filter.get("values"),
        sortFilterValues,
      });
    }

    if (nextProps.activeFilter !== this.props.activeFilter) {
      if (nextProps.activeFilter === this.props.filter.get("id")) {
        this.setState({
          expand: true,
        });

        this.accordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }

  _expandHandler() {
    this.setState({
      expand: !this.state.expand,
    });

    this.accordionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  renderCategory() {
    return (
      <div className="accordion-body">
        <div className="row nopadding">
          {this.props.filter.get("values") &&
            this.props.filter.get("values").map((value, key) => {
              if (!value.getIn(["enabled"])) return null;

              let isActive = value.get("toggled");
              let Icon;

              switch (value.get("id")) {
                case "INSTANTWIN":
                  Icon = isActive ? InstantWinIconActive : InstantWinIconInactive;
                  break;
                case "SLOT":
                  Icon = isActive ? SlotIconActive : SlotIconInactive;
                  break;
                case "VIRTUAL_SPORTS":
                  Icon = isActive ? VirtualSportsIconActive : VirtualSportsIconInactive;
                  break;
                case "TABLEGAME":
                  Icon = isActive ? TableGamesIconActive : TableGamesIconInactive;
                  break;
                case "SCRATCHCARD":
                  Icon = ScratchCardsIcon;
                  break;
                case "ESPORTS":
                  Icon = isActive ? EsportsIconActive : EsportsIconInactive;
                  break;
                case "LIVECASINO":
                  Icon = isActive ? LiveCasinoIconActive : LiveCasinoIconInactive;
                  break;
                case "LOTTERY":
                  Icon = isActive ? LotteryIconActive : LotteryIconInactive;
                  break;
                case "SHOOTING":
                  Icon = isActive ? ShootingIconActive : ShootingIconInactive;
                  break;
                case "CRASH":
                  Icon = isActive ? CrashGamesIconActive : CrashGamesIconInactive;
                  break;
                case "GAME_SHOW":
                  Icon = isActive ? GameShowsIconActive : GameShowsIconInactive;
                  break;
                default:
                  Icon = isActive ? SlotIconActive : SlotIconInactive;
                  break;
              }

              return (
                <div
                  className={`col-4 category-icon text-center ${
                    value.get("toggled") ? "toggled" : ""
                  }`}
                  key={key}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.onFilterClickHandler(
                      this.props.filter.get("id"),
                      value
                    );
                  }}
                >
                  <div className="icon">
                    <Icon />
                    <div className="category-name">{value.get("name")}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  renderDefault() {
    return (
      <div className={`accordion-body ${this.state.showMore ? "expand" : ""}`}>
        {this.state.showMore === false &&
          this.props.filter.get("values") &&
          this.props.filter.get("values").map((value, key) => {
            if (!value.getIn(["enabled"])) return null;
            return (
              <div
                className={`row ${value.get("toggled") ? 'selected' : ''}`}
                key={key}
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.onFilterClickHandler(
                    this.props.filter.get("id"),
                    value
                  );
                }}
              >
                <div className="col">{value.get("name")}</div>
                <div className="col-auto form-check-label">
                  <span>{value.get("count")}</span>
                </div>
                <div className="col-auto">
                  <input
                    className="form-check-input"
                    type={this.props.filter.get('isMultiSelectable') ? "checkbox" : "radio"}
                    aria-label="..."
                    checked={
                      value.get("toggled") ? value.get("toggled") : false
                    }
                    readOnly
                  />
                </div>
              </div>
            );
          })}
        {this.state.showMore === true &&
          this.state.sortFilterValues &&
          this.state.sortFilterValues.map((value, key) => {
            if (!value.getIn(["enabled"])) return null;

            return (
              <div
              className={`row ${value.get("toggled") ? 'selected' : ''}`}
                key={key}
                onClick={(event) => {
                  event.stopPropagation();
                  this.props.onFilterClickHandler(
                    this.props.filter.get("id"),
                    value
                  );
                }}
              >
                <div className="col">{value.get("name")}</div>
                <div className="col-auto form-check-label">
                  <span>{value.get("count")}</span>
                </div>
                <div className="col-auto">
                  <input
                    className="form-check-input"
                    type={this.props.filter.get('isMultiSelectable') ? "checkbox" : "radio"}
                    aria-label="..."
                    checked={
                      value.get("toggled") ? value.get("toggled") : false
                    }
                    readOnly
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  renderVolatility() {
    return (
      <div className={`accordion-body volatility ${this.state.showMore ? "expand" : ""}`}>
        <div className="row nopadding">
          {this.state.showMore === false &&
            this.props.filter.get("values") &&
            this.props.filter.get("values").map((value, key) => {
              if (!value.getIn(["enabled"])) return null;

              return (
                <div
                  className={`col-4 volatility text-center ${
                    value.get("toggled") ? "toggled" : ""
                  }`}
                  key={key}
                >
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.onFilterClickHandler(
                        this.props.filter.get("id"),
                        value
                      );
                    }}
                  >
                    {value.get("name")}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className={`accordion-item ${
          this.props.filter.get("visible") !== false ||
          this.props.filter.get("toggledCount") > 0
            ? ""
            : "d-none"
        }`}
        ref={this.accordionRef}
      >
        <div
          className="accordion-header"
          onClick={(event) => {
            event.stopPropagation();
            this.expandHandler();
            /* this.props.setActiveFilter(
              this.props.filter.get("id") !== this.props.activeFilter
                ? this.props.filter.get("id")
                : "none"
            ); */
          }}
        >
          <div className="accordion-button  w-100">
            <div className="row">
              <div className="col">
                <div>{`${this.props.filter.get("name")} ${
                  this.props.filter.get("toggledCount") &&
                  this.props.filter.get("toggledCount") > 0
                    ? ` (${this.props.filter.get("toggledCount")})`
                    : ""
                }`}</div>
                <div className="sub">
                  {this.props.activeFilter !== this.props.filter.get("id") &&
                    this.props.filter
                      .get("values")
                      .filter((value) => {
                        return value.get("toggled") === true;
                      })
                      .map((value) => {
                        return value.get("name");
                      })
                      .join(", ")}
                </div>
              </div>
              <div className="col-auto">
                {!this.state.expand && <ArrowDown />}
                {this.state.expand && <ArrowUp />}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`accordion-collapse collapse ${
            this.state.expand ? "show" : ""
          }`}
        >
          {this.props.filter.get("id") === "category" && this.renderCategory()}
          {this.props.filter.get("id") !== "category" &&
            this.props.filter.get("id") !== "volatility" &&
            this.renderDefault()}
          {this.props.filter.get("id") === "volatility" &&
            this.renderVolatility()}
          {this.props.filter.get("id") !== "category" && (
            <div
              className="expand-link"
              onClick={() => {
                this.setState({
                  showMore: !this.state.showMore,
                });
              }}
            >
              {this.props.filter.get("enabledCount") === undefined
                ? this.props.filter.get("values").size > 10 && (
                    <span className="btn btn-link">
                      {!this.state.showMore && (
                        <span>{`+${this.props.filter.get("values").size - 10} ${
                          translations('more')
                        }`}</span>
                      )}
                      {this.state.showMore && <span>{translations('less')}</span>}
                    </span>
                  )
                : this.props.filter.get("enabledCount") > 10 && (
                    <span className="btn btn-link">
                      {!this.state.showMore && (
                        <span>{`+${
                          this.props.filter.get("values").size >
                          this.props.filter.get("enabledCount")
                            ? this.props.filter.get("enabledCount") - 10
                            : this.props.filter.get("values").size - 10
                        } ${translations('more')}`}</span>
                      )}
                      {this.state.showMore && <span>{translations('less')}</span>}
                    </span>
                  )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ShowMoreFilter;
